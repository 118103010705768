
.staticButtonStyle{
    width: 50%;
    padding: 5px;
    outline: none;
    border-radius: 100px;
    background: transparent;
    color: white;
    font-weight: bold;
    // background: $blueColorBackground;
    border: solid 1.5px rgb(255, 255, 255);



    &:hover{
        transition: 0.3s;
        // background: $darkBlueColorBackground;
        background: white;
        color: $darkBlueColorBackground;
        border: solid 1.5px rgb(255, 255, 255);
    }
    &:active{
        transform: scale(0.95);
    }

    @media(max-width:975px){
        width: 100%;
        padding: 2px;
    }
}