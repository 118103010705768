@import './../Fixed/staticStyle.scss';

.brandLogo {
    height: 50;
    width: 50;
}

.navLink {
    color: rgb(0, 0, 0) !important;
    font-size: 14px;
    font-family: $fontFamily;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
        color: $blueColorBackground !important;
    }
}

.freeConsultationButton {
    border: solid 1px black;
    color: black;
    padding: 5px;
    font-size: 13px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
}

.subNavBar {
    display: flex;
    justify-content: flex-start;

}

.navLinkActive {
    color: $blueColorBackground;
    font-family: $fontFamily;
    ;
    text-decoration: underline;
    text-underline-offset: 70%;
}

.navLinkActive:hover {
    color: $blueColorBackground;
    font-family: $fontFamily;
    ;
    text-decoration: underline;
    text-underline-offset: 70%;
}

.navBarBGLight {
    // background-color: #ffffff;
    background-image: linear-gradient(to right, #e5ecff, #f4f5f6);
    padding-left: 100px;

    @media(max-width:975px) {
        padding-left: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.navBarBG {
    text-decoration: none;
    background-color: #000000;
}

// ****************************************************
.navbar-nav li:hover>ul.dropdown-menu {
    display: block;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
}

/* rotate caret on hover */
.dropdown-menu>li>a:hover:after {
    text-decoration: none;
    transform: rotate(-90deg);
}

.dropdownItems {
    color: white;
    font-size: 12px;
    font-family: $fontFamily;
    ;
    // background-color: #000000;
}

.dropdownItems:hover {
    color: $blueColorBackground;
    font-size: 12px;
    font-family: $fontFamily;
    ;
    // background-color: #000000;
}

// .active{    
//     color: $blueColorBackground!important;
//     font-family: $fontFamily;;
//     text-decoration: underline;
//     text-underline-offset: 50%;    
//     &:hover{
//         color: $blueColorBackground;
//         font-family: $fontFamily;;
//         text-decoration: underline;
//         text-underline-offset: 50%;
//     }
// }
.menuButton-mobile {
    display: none;

    @media(max-width:975px) {
        display: block;
        padding-left: 10px;
    }
}

.mobileViewHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.headerImageStyle {
    width: 200px;

    @media(max-width:975px) {
        width: 150px;
    }
}

.headerIcons {
    color: $blueColorBackground;
    transition: 0.3s;
    color: $blueColorBackground;
    transition: 0.3s;
    border-radius: 100px;
    padding: 3px;
    height: 20px;
    width: 20px;

    &:hover {
        color: $blueColorBackground;
        transform: scale(1.05);
        border: solid 1px $blueColorBackground;
    }
}

.barStyle {
    color: #a6a2a2;
    font-size: 22px;
}

.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @media(max-width:975px) {
        padding: 10px;
        align-items: center;
    }
}

.navbar-brand {
    @media(max-width:975px) {
        padding-bottom: 0px;
    }
}

.offcanvas-header,
.mobileViewHeader {
    background: #e6ecff;
}

.navbar-collapse {
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 50px;
}

.navbar-nav-scroll {
    display: flex;
    flex-direction: row;
}

.navbar-nav .dropdown-menu {
    position: absolute;
}

.hyperMenuSection {
    // display: block;
    width: 70%;
    position: fixed !important;
    left: 200px;
    padding-block: 2%;
    overflow: scroll;

    &:hover body {
        overflow: hidden;
    }

    @media(max-width:975px) {
        width: 95%;
        left: 10px;
    }
}

.hyperMenuSectionFlex {
    display: flex;
    flex-direction: row;

}

.headSectionStyle {
    height: 350px;
    width: 100%;
    overflow: scroll;
    padding-inline: 15px;
}

.menuImages {
    width: 60px;
    padding: 10px;

    @media(max-width:975px) {
        width: 40px;
        padding-bottom: 5px;
    }
}

.activeMenuSection {
    background-color: $blueColorBackground;
    box-shadow: 7px 7px 7px #0000008b;
    color: white !important;
}

.menuHeadSection1 {
    color: black;
    // width: 300px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: solid 1px rgba(211, 211, 211, 0.558);
    margin-bottom: 10px;
    text-transform: uppercase;
    border-radius: 5px;

    &:hover {
        transition: 0.3s;
        box-shadow: 7px 7px 7px #0000008b;
        cursor: pointer;
        background-color: $blueColorBackground;
        color: white;
        font-weight: bold;
    }

    &:active {
        transform: scale(0.95)
    }

    &:hover img {
        transition: 0.3s;
        transform: scale(1.1)
    }

    @media(max-width:975px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: x-small;
        height: 80px;
    }
}

.menuTitle {
    margin-top: 10px;
    font-size: 18px;
    color: $blueColorBackground;
    border-bottom: solid 1px $lightBlueColorBackground;
    text-align: center;
}

.menuSubHeadSection,
.menuSubMenuSection {
    margin-top: 0px;
}