.payment-button{
    display: flex;
    height: 40px;
    width: 90%;
    justify-content: center;
    align-items: center;
}

.payment-button button{
    height: 100%;
    width: 100%;
    background-color: rgb(63, 63, 216);
    color: white;
}