.booking-container{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    height: auto;
    width: 80%;
    margin-left: 20%;
    box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
}

#top-input{
    margin-top: 30px; 
}

.input-box::placeholder{
    font-size: 12px;
    color: black;
}

.booking-title{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.booking-content{
    display: flex;
    flex-basis: 90%;
    flex-direction: column;
    background-color: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.booking-inputs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20%;
    width:100%;;
}


.input-box{
    height: 50%;
    width:40%;
    padding-bottom: 25px;
    padding-left: 5px;
    border-radius: 5px;
    border: 1px solid #455A64;
}

.margin-left{
    margin-left: 20px;
}

.margin-right{
    margin-right: 20px;
}

.heading-content{
    display: flex;
    align-items: center;
    height: 80%;
    width: 80%;
    margin-left: 20px;
}

.heading-content p{ 
    font-size: 18px;
    font-weight: 600;
}

#logo-border1{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    margin-right: 5px;
    border: 2px solid #407BFF;
    border-radius: 7px;
}

#logo-border2{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    border-radius: 5px;
    margin: 5px 10px 5px 10px;
    border: 2px solid #407BFF;
}

#logo-border2 p{
    font-size: 12px;
    margin: 1px;
    letter-spacing: 2px;
    margin-top: 1px;
    margin-bottom: 1px;
}

#logo-border2 p span{
    color:#407BFF ;
}

.text-area{
    height: 100%;
    width: 96%;
    margin-top: 40px;
    padding-bottom: 55px;
    padding-left: 5px;
    border-radius: 5px;
    border: 1px solid #455A64;
}

.text-area::placeholder{
    font-size: 12px;
    color: black;
}

.comment-area{
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  @media(max-width:970px){
    .booking-container{
        height: 500px;
        width: 80%;
        margin-left: 0px;
    }
  }

  
  @media(max-width:700px){
    .booking-container{
        width: 90%;
    }
  }

  @media(max-width:500px){
    .booking-container{
        width: 100%;
    }
  }