.main{
    display: flex;
    flex-direction: column;
}

.left-side{
    background: linear-gradient(to bottom right, #FFFFFF, #15539C);
  padding-left: 12px;
  padding-right: 12px;
}
.logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo img{
    width: 6rem;
}

.logo p{
    color: #1B2559;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
}
.logo p img{
    width: 1rem;
}
.form{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 35px 35px 24px 24px;
}
.mainForm{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap: 8px;
    margin-top: 8px;
}

select{
    background: #18141f05;
    width: 300px;
    color:grey;
    height:30px;
}

label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (min-width:768px){
    .main{
        flex-direction: row;
    }
    .left-side{
        position: fixed;
    width: 50%;
    height: 100vh;
    }
    .logo img{
        width: 13rem;
    }
    .logo p{
        display:none;
    }
}

@media (min-width: 1024px) {
    .left-side {
      padding-left: 7rem;
      padding-right: 5rem;
    }
  }