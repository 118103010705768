@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.Hero-Section{
    display: flex;
    height: 92vh;
    width: 100%;
}


.Left-section{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 45%;
    margin-left:130px;
}

.Right-section{
    display: flex;
    margin-left: 75px;
    height: max-content;
    width: 55%;
    margin-right: 80px;
}

.Right-section img{
    margin-top: 10%;
    max-height: 100%;
    width:auto;
}

.Tools-Big-Text{
    display: flex;
    flex-direction: column;
    margin-top: 100px ;
    font-size: 35px;
    font-family: 'Cabin';
    font-weight: 550;
    height: auto;
    width: max-content;
}

.Tools-Text1{
    text-align: left;
}

.Tools-Text2{
    display: flex;
    align-items: center;
    font-family: 'Cabin';
    font-weight: 550;
}

.Tools-Text2 img{
    height: 30px;
    width: 30px;
    margin-right: 5px;
}

.Tools-Text1 span{
    color: rgba(28, 110, 208, 1);
}

.Tools-Text2 span{
    color: rgba(28, 110, 208, 1);
}

.Tools-Small-Text{
    display: flex;
    font-size: 16px;
    font-family:'Inter';
    font-weight: 100;
    margin-top: 10px;
    text-align: left;
    height: auto;
    color:black;
    opacity: 80%;
    width: 450px;
}

.Features-Div{
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-top: 50px;
    width: 300px;
}

.Feature{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    align-items: center;
}

.Feature p{
    opacity: 80%;
}

.Feature img{
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.Options-Div{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    width: 400px;
    margin-top: 50px;
}

.Option{
    display: flex;
    background-color: rgba(255, 255, 255, 1);
    align-items: center;
    height: 50px;
    width: 170px;
    padding: 1px;
    font-size: 14px;
    margin-bottom: 20px;
    border-radius: 50px;
    color:black;
    border: 1px solid rgba(38, 50, 56, 0.35);
}

.Option img{
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.Option p{
    margin-left: 10px;
    opacity: 80%;
}

/*Code for Responsiveness*/
@media(max-width:1280px){
    .Tools-Big-Text{
        font-size: 30px;
    }
}

/*Tablet Responsiveness*/
@media(max-width:1020px){
    .Hero-Section{
        flex-direction: column;
        align-items: center;
        height:max-content;
    }

    .Left-section{
        width:90%;
        margin-left: 0px;
    }

    .Features-Div{
        width: 350px;
    }

    .Options-Div{
        margin-right: 0px;
        width:400px;
    }
}
/*Code For Mobile Screen*/
@media(max-width:724px){

    .Right-section img{
        max-width: 100%;
        height: auto;
    }

    .Tools-Big-Text{
        margin-top: 50px;
        font-size: 24px;
    }

    .Tools-Small-Text{
        font-size: 13px;
        font-weight: 300;
        text-align: left;
    }

    .Features-Div{
        width:300px;
        justify-content: space-between;
    }
    
    .Feature{
        margin-right: 10px;
        font-size: 12px;
        width: 50%;
    }

    .Feature img{
        margin-right: 5px;
    }

    .Options-Div{
        justify-content: left;
        width:75%;
    }
    
    .Option{
    height: 50px;
    width: 160px;
    font-size: 12px;
    font-family: 'Inter',sans-serif;
    }

    .Option img{
        height: 30px;
        width: 30px;
        margin-left: 10px;
    }

    .Option:nth-child(1),.Option:nth-child(3){
        margin-right: 10px;
    }
    
}

@media(max-width:500px){
    .Left-section{
        width:90%;
        padding-top: 25px;
    }

    .Right-section{
        height: max-content;
        width: max-content;
        margin-top: 40px;
        align-self: center;
    }

    .Right-section img{
        max-width:300px;
    }
    .Options-Div{
        width: 100%;
        justify-content: space-between;
    }

    .Tools-Big-Text{
        margin-top: 50px;
    }

    .Tools-Small-Text{
        width:300px;
    }

    .Options-Div{
        width:280px;
    }

    .Option{
        height: 40px;
        width: 135px;
        font-size:10px;
    }

    .Option img{
        height: 20px;
        width: 20px;
    }

    .Option:nth-child(1),.Option:nth-child(3){
        margin-right: 5px;
    }

}

@media(max-width:400px){
    .Left-section{
        width:90%;
    }
}

