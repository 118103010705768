.app1{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.section{
    height: auto;
    align-self: center;
    width: 80%;
}

@media(max-width:500px){
    .section{
        width: 95%;
    }
}