// @media (max-width: 1250px) {
//     p {
//         font-size: small;
//     }
//     h6 {
//         font-size: 12px;
//     }
// }

.filter-wrapper {
  position: relative;
  display: inline-block;
}

.filter-button {
  border-radius: 5px;
  padding: 8px 16px;
  background-color: #408aeb;
  cursor: pointer;
  font-weight: 500;
  color: white;
  font-size: 18px;
  line-height: 1.5;
}

.filter-button:hover {
  background-color: #092b58;
}

.filter-button:focus {
  outline: 1px dashed;
}

.filter-options {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px;
  z-index: 1;
}

.filter-option {
  border: none;
  background-color: transparent;
  color: #333;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 8px;
}

.filter-option:hover {
  background-color: #f0f0f0;
}

.comparison-tray {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.close-icon {
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  color: #aaa;
}

.close-icon:hover {
  color: red;
}

// .comparison-tray button {
//   background-color: #407bff;
//   color: #fff;
//   padding: 1rem;
//   border-radius: 5px;
// }


/* Mobile styles */

@media only screen and (max-width: 768px) {
  .comparison-tray {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    flex-direction: column;
    align-items: center;
  }

  .comparison-tray ul {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
  }

  .comparison-tray li {
    width: 20%;
    margin: 10px;
    flex-basis: auto;
    flex-grow: 1;
  }

  // .comparison-tray button {
  //   width: 50%;
  //   margin: 10px;
  //   padding: 10px;
  // }

  // .comparison-tray button:first-child {
  //   margin-right: 10px; /* add some space between the buttons */
  // }
}