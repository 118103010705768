.app{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 98.5vw;
}

#navBar{
  height: auto;
  width: 100%;
  flex-basis: 5%;
}

#head-section{
  display: flex;
  align-items: end;
  flex-basis: 10%;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

#head-section p{
  margin-left: 12%;
}

.sections{
  display: flex;
  flex-basis: 80%;
  align-self: center;
  width: 100%;
}

.booking-section1{
  display: flex;
  justify-content: center;
  height: auto;
  width: 60%;
}


.booking-section2{
  display: flex;
  height: auto;
  width: max-content;
}

@media(max-width:970px){

.sections{
  flex-direction: column;
  align-items: center;
}

.booking-section1{
  width: 90%;
}

}

@media(max-width:500px){


#head-section p{
  margin-top: 5px;
  margin-left: 5%;
  margin-bottom: 5px;
}
}



