.ToolsPage{
  display: flex;
  flex-direction: column;
  height: "100%";
  font-family: 'Cabin';
  width: 100%;
}

.nav-section{
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
}

.nav-section-main{
  display: flex;
  align-items: center;
  height: 100%;
  width: 90%;
}

.nav-section-main img{
  height: 30px;
  width: 160px;
}

.Main-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  width: 100%;
}

.Cards-Section{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
}

.Card-Container{
  height: auto;
  width: 90%;
}

.Container-Title{
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 50px;
  margin-top: 50px;
}

.Container-Title p span{
  color: rgba(28, 110, 208, 1);
}

.Title-Line{
  border: 1px solid rgba(38, 50, 56, 1);
}

#Title-Line1{
  width: 68%;
}

#Title-Line2{
  width: 82%;
}

#Title-Line3{
  width: 82%;
}

#Title-Line4{
  width: 77%;
}

.Container-Content{
  display: flex;
  align-items: center;
  height: 300px;
  width: 100%;
}

.Container-Content-Empty{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
}

.Coming-soon{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: auto;
}

.Coming-soon img{
  height: 100px;
  width: 100px;
  margin-right: 20px;
}

.Coming-soon-text1{
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 10px;
}

.Coming-soon-text2{
  font-size: 14px;
}

/*Code For Mobile Screen*/
@media(max-width:500px){
  .ToolsPage{
    overflow-x: hidden;
    overflow-y: auto;
  }
  .Cards-Section{
    margin-top: 50px;
  }

  .Container-Title{
    font-size: 25px;
  }

  #Title-Line1{
    width: 40%;
  }
  
  #Title-Line2{
    width: 40%;
  }

  .Coming-soon img{
    height: 80px;
    width: 80px;
    margin-right: 20px;
  }
  
  .Coming-soon-text1{
    font-size: 25px;
  }
  
  .Coming-soon-text2{
    font-size: 12px;
  }
}