.rating-container{
    display: flex;
    position: relative;
    flex-direction: column;
    left: 140px;
    height: 50px;
    width:350px;
}

.full-width{
    width: 750px;
    left: 130px;
    
}


#heading{
    display: flex;
    align-items: flex-start;
    font-size: 40px;
    color: #007CFF;
    letter-spacing: 5px;
}

#heading p{
    font-weight: bold;
}

.rating-items{
    display: flex;
    color: #455A64;
    height: 20%;
}

.rating-items-clickable{
    display: flex;
    position: relative;
    color: #455A64;
    margin-top: 10px;
    right: 5px;
    height: 20%;
}

#ratings{
    flex-direction: column;
}

#ratings p{
    position: relative;
    width: 50px;
}

#stars{
    position:relative;
    width: 40%;
    right: 12px;
}

.header-items{
    display:flex;
    position: relative;
    width: 100px;
    color: #455A64;
    margin-left: 8px;
    right: 5px;
    top: 2px;
    align-items: center;
    cursor: pointer;
}

.box{
    display: flex;
    position: relative;
    top: 5px;
    height: 20px;
    width:20px;
    margin: 2px;
}

.header-items p{
    margin-bottom: 2px;
}

.rating-item{
    display: flex;
    align-items: center;
    margin: 5px;
}

.icons{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    background-color: #414c52;
    margin-right: 5px;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.2s;
    width:20px;
}

.icons:hover{
    transform: scale(1.2);
}

.icon{
    height:60%;
    width: 60%;
}


@media (max-width:1200px){
    .rating-container{
        left:0px;
    }

    .full-width{
        width:350px;
    }

    .rating-container{
        right: 300px;
    }
}

@media (max-width:850px) {
    .rating-container{
        left: 20px;
    }
}

@media (max-width:420px){
    .rating-container{
     width: 100%;
    }
    
    .full-width{
        width: 100%;
        left: 0px;
        
    }
}