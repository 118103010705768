/* Custom class to hide scrollbar */
.hide-scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  input::placeholder,
textarea::placeholder {
    font-weight: 100; /* Adjust the weight to your preference */
    color: #808080; /* Use the same color code for both */
    opacity: 0.7;
}
/* input[type="text"]::placeholder{
 opacity: 1;
  color: #808080;
} */