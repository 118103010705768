@import './fixedStyle.scss';
@import './buttonStyle.scss';


.bodySectionHead {
    font-family: $fontFamily;
}

.headSection {
    font-family: $fontFamily;
    font-size: 35px;
    font-weight: bold;
    border-bottom: solid 2px $goldColor;
    width: 500px;
    text-align: left;

    @media(max-width:975px) {
        width: 100%;
        font-size: 30px;
    }
}

.bodySection {
    font-family: $fontFamily;
    margin-top: 10px;
    font-size: 15px;
    width: 80%;
    text-align: left;
    color: grey;
    text-align: justify;

    @media(max-width:975px) {
        width: 100%;
        font-size: 12px;
    }
}

.bodySection2 {
    font-family: $fontFamily;
    font-size: 15px;
    width: 100%;
    text-align: center;
    color: grey;
    text-align: justify;

    @media(max-width:975px) {
        width: 100%;
        font-size: 12px;
    }
}

.flexDivSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media(max-width:975px) {
        display: block;
    }
}

.flexDivCenterPhone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media(max-width:975px) {
        justify-content: center;
        flex-direction: column;
    }
}

.justifyText {
    text-align: justify;
}

.justifySection {
    justify-content: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.fullWidth {
    width: 100% !important;
}

.fullHeight {
    height: 100%;
}

.width100 {
    width: 100% !important;
}

.width80 {
    width: 80% !important;
}

.width50 {
    width: 50% !important;
}

.width30 {
    margin-left: 10px;
    width: 30% !important;
}

.fixedWidth150 {
    width: 150px;
}

.width200px {
    width: 200px !important;
}

.width300px {
    width: 300px !important;
}

.width100px {
    width: 100px !important;
}

.pointer {
    cursor: pointer;
}

.center {
    text-align: center;
}

.centerDiv {
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.fontSize10 {
    font-size: 10px;
}

.fontSize11 {
    font-size: 11px;
}

.fontSize12 {
    font-size: 12px;
}

.fontSize13 {
    font-size: 13px;
}

.fontSize15 {
    font-size: 15px !important;
}

.fontSize18 {
    font-size: 18px;
}

.fontSize20 {
    font-size: 20px;
}

.fontSize25 {
    font-size: 25px;
}

.headSection2 {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    color: $goldColor;
    border-bottom: solid 2px $goldColor;

    @media(max-width:975px) {
        padding: 2px;
        font-size: 15px;
    }
}

.borderBottom {
    border-bottom: solid 2px $goldColor;
}

.headSection3 {
    font-size: 18px;
    font-weight: bold;
    padding: 0px;
    color: $goldColor;

    @media(max-width:975px) {
        font-size: 16px;
    }
}

.headSection4 {
    font-size: 18px;
    padding: 5px;

    @media(max-width:975px) {
        font-size: 14px;
    }
}

.bodySectionWhite {
    padding: 10px;
    color: white;

    @media(max-width:975px) {
        padding: 2px;
    }
}

.colorWhite {
    color: white !important;
}

.colorBlack {
    color: black !important;
}

.colorPrimary {
    color: $blueColorBackground;
}

.colorGold {
    color: $goldColor
}

.bodySectionBlack {
    padding: 0px;
    color: black;
}

.padding0 {
    padding: 0;
}

.paddingPhone0 {
    @media(max-width:975px) {
        padding: 0;
    }
}

.paddingTop5 {
    padding-top: 5px;
}

.padding5 {
    padding: 5px;
}

.padding10 {
    padding: 10px;
}

.padding20 {
    padding: 20px;
}

.paddingH100 {
    padding-left: 20%;
    padding-right: 20%;

    @media(max-width:975px) {
        padding-left: 5%;
        padding-right: 5%;

    }
}

.padding30 {
    padding: 30px;
}

.padding50 {
    padding: 50px;
}

.paddingLR50 {
    padding-left: 50px;
    padding-right: 50px;
}

.paddingLeft100 {
    padding-left: 100px;
}

.webPaddingRight20 {
    padding-right: 20px;

    @media(max-width:975px) {
        padding-right: 0px;
    }
}

.webPaddingRight10p {
    padding-right: 10%;

    @media(max-width:975px) {
        padding-right: 0px;
    }
}

.webPaddingRight6p {
    padding-right: 6%;

    @media(max-width:975px) {
        padding-right: 0px;
    }
}

.webPaddingRight50 {
    padding-right: 50px;

    @media(max-width:975px) {
        padding-right: 0px;
    }
}

.marginLeft5 {
    margin-left: 5px;
}

.marginLeft10 {
    margin-left: 10px;
}

.marginTop5 {
    margin-top: 5px;
}

.marginTop10 {
    margin-top: 10px !important;
}

.marginTop20 {
    margin-top: 20px !important;
}

.marginTop30 {
    margin-top: 30px !important;
}

.marginBottom10 {
    margin-bottom: 10px !important;
}

.marginTop40 {
    margin-top: 40px !important;
}

.marginTop50 {
    margin-top: 50px !important;
}

.marginTopMobile60 {
    @media(max-width:975px) {
        margin-top: 60px;
    }
}

.marginTopMobile80 {
    @media(max-width:975px) {
        margin-top: 80px;
    }
}

.marginTop70 {
    margin-top: 70px;
}

.goldButton {
    background: $goldColor;
}

.mobileTransformLow7 {
    transform: scale(1);

    @media(max-width:975px) {
        transform: scale(0.7);
    }
}

.transformLow7 {
    transform: scale(0.7);

    @media(max-width:975px) {
        transform: scale(1);
    }
}

.transformLow8 {
    transform: scale(0.8);

    @media(max-width:975px) {
        transform: scale(1);
    }
}

.transformLow9 {
    transform: scale(0.9);

    @media(max-width:975px) {
        transform: scale(1);
    }
}

.hoverZoom {
    transform: scale(1);

    &:hover {
        transform: scale(1.02);
        transition: 0.3s;
    }
}

.boxShadow {
    box-shadow: 10px 10px 10px #00000022;
}

.boxShadowHover {
    &:hover {
        transition: 0.3s;
        box-shadow: 8px 8px 8px #00000022;
    }
}


.paddingWindowT {
    padding-top: 5%;
    padding-left: 12%;
    padding-right: 12%;
}


.paddingWindow {
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 12%;
    padding-right: 12%;
}

.paddingWindow2 {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 12%;
    padding-right: 12%;
}

.phonePaddingWindow {
    @media(max-width:975px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.reverseImage {
    transform: rotate(180deg);
}

.marginTopMinus50 {
    margin-top: -500px;
}

.fixHeight100 {
    height: 100px;
    overflow: hidden;

    @media(max-width:975px) {
        height: 20px;
    }
}

.overflowScroll {
    overflow: scroll;
}

.overflowHidden {
    overflow: hidden;
}

.fixWidth100 {
    width: 100px;
}

.fixWidth200 {
    width: 200px;
}

.phoneViewFull {
    @media(max-width:975px) {
        width: 100%;
    }
}

.hidePhoneView {
    @media(max-width:975px) {
        display: none !important;
    }
}

.showPhoneView {
    display: none !important;

    @media(max-width:975px) {
        display: block !important;
    }
}

.bottomLineText {
    border: none;
    border-bottom: solid 2px $goldColor;
    outline: none !important;

    &:focus {
        outline: none !important;
    }
}

.flexRight {
    display: flex;
    justify-content: flex-end;
}

.flexPhoneRight {
    @media(max-width:975px) {
        display: flex;
        justify-content: flex-end;
    }
}

.flexLeft {
    display: flex;
    justify-content: flex-start;
}

.flexPhoneLeft {
    @media(max-width:975px) {
        display: flex;
        justify-content: flex-start;
    }
}

.fixedStyleForMobile {
    @media(max-width:975px) {
        position: fixed;
        width: 100%;
        height: 8%;
        z-index: 99;
    }
}

.strikethroughText {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        right: 0;
        border-top: 1px solid;
        border-color: inherit;

        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
}

.phoneMarginTop10 {
    @media(max-width:975px) {
        margin-top: 10px;
    }
}

.upperCase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.fontWeight500 {
    font-weight: 500;
}

.borderRadius {
    border-radius: 10px;
}

.webSection {
    display: block;
    transition: 0.3s;

    @media(max-width:975px) {
        display: none;
    }
}

.mobileSection {
    display: none;
    transition: 0.3s;

    @media(max-width:975px) {
        display: block;
    }
}

.phonePositionRight {
    position: absolute;
    right: 0;
}

.phonePositionLeft {
    position: absolute;
    left: 0;
}

.flex {
    display: flex;
    align-items: center;
}

.borderRightWeb {
    border-right: solid 1px $goldColor;

    @media(max-width:975px) {
        border-right: none;
    }
}

.borderRightPhone {
    border-right: none;

    @media(max-width:975px) {
        border-right: solid 1px $goldColor;
    }
}

.borderBottomPhone {
    border-bottom: none;

    @media(max-width:975px) {
        border-bottom: solid 1px $goldColor;
    }
}

// Alert Fox Style
.rnc__base {
    right: -40px;
}

.rnc__notification-container--top-right {
    text-align: left;
    transform: scale(0.8);
}

.subHeadingSection {
    // height: 200px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media(max-width:975px) {
        background-size: cover;
        height: auto;
    }
}

.subHeadingSectionBg {
    // height: 200px;
    width: 100%;
    background-image: linear-gradient(to right, #fcd845, #c2a93f7d, #dcb4141f);
    padding: 50px;
    text-align: left;
    font-weight: bold;
    font-size: 35px;
    text-transform: uppercase;
    text-shadow: 5px 5px 5px #4949499e;

    @media(max-width:975px) {
        padding: 20px;
        text-align: center;
        font-size: 18px;
        height: auto;
    }
}

.textEllipses2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lightColor {
    color: grey;
}

.textCapitalize {
    text-transform: capitalize !important;
}

.textDecoration {
    text-decoration: none;
    color: black;
}

.removeImageBg {
    mix-blend-mode: color-burn;
}

.touchable {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        transform: scale(1.05);
    }

    &:active {
        opacity: 0.3;
    }
}