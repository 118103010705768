#summary-container{
    display:flex;
    flex-direction: column;
    height: auto;
    width:300px;
    box-shadow: 0 4px 3px -2px rgba(0, 0, 0, 0.2); 
    background-color: white;
    padding-bottom: 20px;
}

.summary-logo{
    display: flex;
    align-items: center;
    border-radius: 15px;
    height: auto;
    margin-left: 5px;
    border: 2px solid #A1D4C9;
}

.summary-logo p{
    font-size: 22px;
    font-weight: 800;
    letter-spacing: 4px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    color: #455A64;
}

.summary-logo p span{
    color: #A1D4C9;
}

#summary-heading{
    display: flex;
    color: #1B2559;
    background-color: #cbe3fd;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    height: 50px;
    width:100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

#summary-heading p{
font-size: 20px;
font-weight: 800;
}

.summary-items{
    border-bottom:1px solid gray;
    padding-bottom: 5px;
}

.summary-content{
    display:flex;
    height: auto;
    margin: 5px;
    justify-content: space-between;
}

.summary-item2{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    flex-basis: 40%;
    color: #1B2559;
    font-size: 18px;
    font-weight: 800;
    justify-content: center;
    align-items: center;
}

.summary-item2 p{
    margin-top: 1px;
    margin-bottom: 1px;
}

.summary-item1{
    display: flex;
    margin-left: 10px;
    flex-basis: 60%;
    color: #1B2559;
    font-size: 14px;
    font-weight: 100;
    align-items: center;
}

.summary-item1 p{
    margin-top: 1px;
    margin-bottom: 1px;
}

.press-release-content{
    display: flex;
    width: 100%;
    color: #1B2559;
    font-weight: 600px;
    font-size: 18px;
}

.press-release-section{
    display: flex;
    width: 100%;
}

.press-release-section p{
    margin-left: 12px;
}

#total{
    display:flex;
    font-weight: 800;
    margin-bottom: 20px;
}

#btn-container{
    display: flex;
    justify-content: center;
    flex-basis: 100%;
}

#proceed{
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    width:90%;
    margin-top: 10px;
    border-radius: 15px;
    background-color: rgb(0, 60, 255);
    font-size: 22px;
    color:white;
    transition: 0.1s;
}

#proceed:hover{
    transform: scale(1.05);
}

.total-item1{
    display: flex;
    margin-left: 10px;
    flex-basis: 60%;
    color: #1B2559;
    font-size: 18px;
    font-weight: 800;
    align-items: center;
}

.total-item2{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    flex-basis: 40%;
    font-weight: 800;
    color: #1B2559;
    font-size: 18px;
    justify-content: center;
    align-items: center;
}


@media (max-width:760px){
    #summary-container{
        margin-left: 0px;
    }
}