@import './../Fixed/staticStyle.scss';

.searchIcon {
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;
  color: #696161;
  font-size: 13px;
}

.clearServiceIcon {
  position: absolute;
  margin-top: 15px;
  margin-left: -25px;
  color: #696161;
  font-size: 13px;
  transition: 0.3s;

  &:hover {
    transform: scale(1.1);
    color: #ff0000a1;
    cursor: pointer;
  }
}

.closeIcon {
  background: $lightBlueColorBackground;
  font-size: 35px;
  padding: 10px;
  border-radius: 100px;
  margin-left: 20px;
  color: $blueColorBackground;
  transition: 0.3s;

  &:hover {
    border: solid 2px $darkBlueColorBackground;
    cursor: pointer;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  @media(max-width:675px) {
    position: absolute;
    margin-top: -8%;
    margin-left: -2%;
    font-size: 25px;
    padding: 5px;
  }
}

.serviceStyle {
  overflow: scroll;
}

.serviceTypeHead {
  padding-bottom: 20px;
}

.serviceTypeHead::-webkit-scrollbar {
  display: none;
}

.serviceType {
  margin-left: 20px;
  padding: 10px;
  min-width: 150px;
  background: #d3d3d361;
  text-align: center;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  border: solid 1px white;
  text-transform: capitalize;

  &:hover {
    transition: 0.3s;
    background: $lightBlueColorBackground;
    border: solid 1px $blueColorBackground;
  }

  &:active {
    transform: scale(0.95);
  }
}

.serviceTypeHead .slick-slide {
  margin-left: 10px;
}

.selectedServiceType {
  margin-left: 20px;
  padding: 10px;
  min-width: 150px;
  background: $lightBlueColorBackground;
  text-align: center;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  border: solid 1px $blueColorBackground;
  text-transform: capitalize;

  &:hover {
    transition: 0.3s;
  }

  &:active {
    transform: scale(0.95);
  }
}

.servicesList {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  height: 120px;
  justify-content: center;
  border-radius: 5px;

  img {
    opacity: 0.8;
  }

  &:hover {
    transition: 0.3s;
    cursor: pointer;
    box-shadow: 0px 0px 10px 5px #00000040;

    img {
      transition: 0.3s;
      transform: scale(1.1);
      opacity: 1;
    }
  }

  @media(max-width:975px) {
    margin-top: 10px;
  }

}

.serviceImage {
  width: 60px;
}

.serviceImageText {
  color: grey;
  font-size: small;
  margin-top: 10px;

  @media(max-width:975px) {
    font-size: small;
  }
}

.serviceTypeBody {
  max-height: 450px;
  min-height: 200px;
  overflow: scroll;
  padding: 10px;
}