.app{
  display:flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  font-family: 'Cabin';
  height: auto;
  width:100%; 
}

nav{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  height:25%;
  width:100%; 
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
}

#logo{
  height: auto;
  width:auto;
  margin-left: 40px;
}

#logo-text{
  height: 10px;
  width: 100px;
}

#logo h2{
  margin: auto;
}

#need-help{
  display: flex;
  align-items: center;
  position: relative;
  height: auto;
  width:auto;
  margin-right: 40px;
  color: blue;
  font-weight: bold;
  cursor: pointer;
}

.call-logo{
  background: linear-gradient(to bottom, #acc3f5 0%, white 100%);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 5px;
}


#need-help p{
  margin-left: 5px;
  font-weight: 100;
}

.call-logo img{
  margin-top: 5px;
  margin-left: 5px;
  height: 70%;
  width: 70%;
}

.content-About{
  display:grid;
  grid-template-columns: 70vw 30vw;
  width: 100%;
  height: auto;
}


.section1{
  display: flex;
  margin-top: 20px;
  height: 50%;
}

#logo-box{
    display: flex;
    height: 200px;
    width:300px;
    border:3px solid #007CFF;
    background-color: #cbe3fd;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-left: 75px;
}


#logo-item{
  display: flex;
  height: 50%;
  width:60%; 
  justify-content: center;
  font-size: 40px;
  letter-spacing: 5px;
  font-weight: bold;
  align-items: center;
  border-radius: 15px;
  background-color: #F7FCFB;
}

#X{
  color: #007CFF;
}


.section2{
  display: flex;
  height: auto;
  margin-top: 20px;
  width:300px;
}



.section3-About{
  position: relative;
  height: auto;
  margin-left: 20px;
  width: 95%;
  bottom:80px;
}


@media (max-width:1200px) {
  .content-About{
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .section1{
    display: flex;
    width:90%;
    order: 1;
    justify-content: space-between;
    margin-top: 20px;
  }

  .section3-About{
    position: relative;
    height: auto;
    top: 75px;
    justify-content: center;
    order: 2;
    margin-bottom: 10%;
    margin-top:5%;
    width: 90%;
  }

  .section2{
    position: relative;
    order: 3;
    align-self: center;
    margin-bottom: 10%;
  }
}

@media (max-width:800px){
  .section2{
    top: 20px;
  }
}


@media (max-width:530px){

  .section1{
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .section3-About{
    position: relative;
    height: auto;
    top: 10px;
    justify-content: center;
    order: 2;
    margin-left: 0px;
    margin-bottom: 10%;
    width: 90%;
  }

  
#logo-box{
  margin-bottom: 20px;
  margin-left: 0px;
}

#logo-text{
  height: 20px;
  width: 100px;
}

.section2{
  top: 40px;
  margin-bottom: 80px;
}
}

@media(max-width:400px){
  .section3-About{
    width: 95%;
  }
}