.elevation{
    -webkit-box-shadow: 10px 10px 15px -2px rgba(194,186,194,0.71);
    -moz-box-shadow: 10px 10px 15px -2px rgba(194,186,194,0.71);
    box-shadow: 10px 10px 15px -2px rgba(194,186,194,0.71);
}
.elevation2{
    -webkit-box-shadow: 10px 10px 15px -2px rgba(143,130,143,1);
    -moz-box-shadow: 10px 10px 15px -2px rgba(143,130,143,1);
    box-shadow: 10px 10px 15px -2px rgba(143,130,143,1);
}
