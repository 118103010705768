#detail-container{
    height: auto;
    width:90%;
    background-color: white;
    border-radius: 5px;
    text-align: justify;
    margin-left: 50px;
}

#detail-container h4{
    margin-left: 5px;
}

.detail-sections{
    display: flex;
    flex-direction: column;
}

.detail-section{
    display: grid;
    grid-template-columns: 50% 50%;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2); 
    font-size: 15px;
    row-gap: 10px;
    height: auto;
    padding-bottom: 7px;
    width:98%;
}

.image{
    height: 20px;
    width: 20px;
}

.detail-content{
    display:flex;
    margin-top: 15px;
    height: auto;
    width: 100%;
    flex-direction: column;
}

.marginTop{
    margin-top: 20px;
}

.detail-content p{
    margin-top:auto;
    margin-bottom: auto;
}

.detail-heading{
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: auto;
}

.symbol{
    height: 20px;
    width:20px;
    margin-right: 5px;
}

.heading{
    height: auto;
}

.heading p{
    color: #455A64;
    font-size: small;
}

.full-width{
    grid-column-start: 1;
    grid-column-end: 3;    
} 


#features{
    display:flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 600;
    margin-left: 35px;
}

.details{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    width: 100%;
}

.details1{
    column-gap: 10%;
    justify-content: space-around;
}

.details2{
    width: 64%;
    column-gap: 10%;
    justify-content: space-around;
}

.detail-cards2-1{
    position: relative;
    right: 10px;
}

.detail-cards2-2{
    position: relative;
    left: 5px;
}

.detail-card{
    display: flex;
    justify-content: center;
    height:auto;
    width:auto;
    min-width: 150px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    background-image: linear-gradient(90deg,#e5ecff,#f4f5f6);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
}



.detail-card p{
    font-size: 15px;
    text-align: justify;
    line-height: normal;
    color: #263238;
    font-weight: 600;
}

.detail-text{
    color: #263238;
    font-size: 15px;
    font-weight: 600;
    margin-left: 35px;
}

#All-details{
  color: #263238;
  font-weight: 600;
  font-size: 16px;
}

#Guaranteed-Placement{
    color: #263238;
    font-weight: 600;
    font-size: 16px;
    margin-left: 25px;
}


#btn-box{
    display: flex;
    width: 20%;
    align-items: center;
    flex-direction: column;
    margin: 10px;
}

#download{
    display: flex;
    height:40px;
    width:150px;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
    background-color: rgb(0, 60, 255);
    color:white;
    transition: 0.1s;
}

#download img{
    height: 22px;
    width: 22px;
}

#download:hover{
    transform: scale(1.05);
}

@media (max-width:1200px){
    #detail-container{
        align-self: center;
        margin-left: 10%;
        width: 80%;
    }



    #download{
        margin-left: 40px;
    }
}

@media(max-width:900px){
    .detail-cards2-1{
        right: 0px;
    }
    
    .detail-cards2-2{
        left: 0px;
    }
}

@media(max-width:810px){
    .details1{
        column-gap: 2%;
        justify-content: space-around;
    }
    
    .details2{
        width: 65%;
        column-gap: 3%;
    }

    .detail-cards2-2{
        left: 5px;
    }

}


@media (max-width:700px){
   
    #detail-container{
        align-self: center;

    }
}

@media (max-width:670px){
.details2{
    width: 100%;
    column-gap: 2%;
    justify-content: space-around;
}
}

@media (max-width:530px){
    #detail-container{
        align-self: center;
        margin-left: 0px;
        width: 100%;
        margin-top: 5rem;

    }

    .detail-cards2-2{
        left: 0px;
    }

    .detail-section{
        display: flex;
        flex-direction: column;
        height: auto;
        width:95%;
    }

    .detail-content{
        margin-top: 5px;
        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2); 
        padding-bottom: 10px;
    }

    .detail-section{
        box-shadow: none;
    }

    #download{
        margin-left: 80px;
    }


}


