
@import './../Fixed/staticStyle.scss';

.subHeaderMainDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    background: $darkBlueColorBackground;
    font-size: small;
    color: white;
    font-weight: 500;
    padding-left: 7%;
    padding-right: 7%;
}

.subHeaderRow {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navLinks{
    text-decoration: none;
    color: white;
    text-transform: uppercase;
}