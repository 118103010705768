#summary-container-2{
    position: relative;
    display:flex;
    flex-direction: column;
    height: 100%;
    width:300px;
    left: 20%;
    box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.75); 
    background-color: white;
    border-radius: 5px;
}

#summary-heading-2{
    display: flex;
    color: #1B2559;
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 20px; 
    justify-content: center;
    width:100%;
}

.summary-items-2{
    border-top: 1px solid #49517A;
    border-bottom:1px solid #49517A;
    align-self: center;
    width:90%;
    padding-bottom: 10px;
}

.summary-content-2{
    display:flex;
    height: 40px;
    margin: 2px;
    justify-content: space-between;
}


.summary-item2-2{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    flex-basis: 40%;
    color: #1B2559;
    font-size: 18px;
    font-weight: 800;
    justify-content: center;
    align-items: center;
}

.summary-item1-2{
    display: flex;
    margin-left: 10px;
    flex-basis: 60%;
    color: #1B2559;
    font-size: 18px;
    font-weight: 100;
    align-items: center;
}


.summary-item2-2 p{
    margin-top: 2px;
    margin-bottom: 2px;
}

#total-2{
    display:flex;
    font-weight: 800;
}

#btn-container-2{
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}

#pay-button-2{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width:90%;
    border-radius: 8px;
    background-color: rgb(0, 60, 255);
    font-size: 22px;
    color:white;
    transition: 0.1s;
}

#pay-button-2:hover{
    transform: scale(1.05);
}

.total-item1-2{
    display: flex;
    margin-left: 28px;
    flex-basis: 60%;
    color: #1B2559;
    font-size: 18px;
    font-weight: 800;
    align-items: center;
}

.total-item2-2{
    display: flex;
    margin-right: 10px;
    flex-direction: column;
    flex-basis: 40%;
    font-weight: 800;
    height: 60px;
    color: #1B2559;
    font-size: 18px;
    justify-content: center;
    align-items: center;
}

#GST-text-2{
    position: relative;
    margin-bottom: 0px;
    bottom: 5px;
    font-size: 10px;
}

#amount-text-2{
    position: relative;
    color: rgb(0, 60, 255);
}

#installment-info-2{
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 40px;
    margin-left: 10%;
}

#installment-info-2 p{
    font-size: 6px;
}

.installment-text-2{
    width: 65%;
    height:auto;
}

.installment-input-2{
    display: flex;
    height: 35px;
    width:50px;
    border-radius: 5px;
    margin: 0px;
    border: 1px solid black;
}

.agreement-input-2{
    display: flex;
    height: 25px;
    width:35px;
    margin: 0px;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid black;
}

.payment-type-2{
    display: flex;
    height: 18px;
    width:35px;
    margin: 0px;
    margin-left: 2px;
    margin-right: 3px;
}

.extra-details-2{
    display: flex;
    align-items: center;
    width: 90%;
    height: 30px;
    margin-left: 5%;
}

.extra-details-2 p{
    font-size: 9px;
}

#tick-2{
    height: 100%;
    width: 100%;
}

.extra-text-2{
    width: 80%;
    height:auto;
}

@media (max-width:970px){
    #summary-container-2{
        margin-top: 20px;
        margin-bottom: 20px;
        left: 0px;
    }
}

@media (max-width:600px) {
    #summary-container-2{
        height: 94%;
        width:80%;
        left: 10%;
    }
}