@import './../Fixed/staticStyle.scss';

.productImageSection{
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 5%;
  padding-bottom: 5%;

}

.productTextSection{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productMainHead{
  width: 100%;
  padding-inline: 10%;
  padding-block: 5%;
  background-color: #ffffff;
  height: 100vh;
  text-align: left;
  @media(max-width:975px){
    height: 100%;
  }
}

.productHeadSection{
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, $blueColorBackground, $darkBlueColorBackground);
  flex-direction: row;
  
  @media(max-width:975px){
    flex-direction: column;
  }
}

.css-13cymwt-control{
  background-color: #0000000a!important;
}