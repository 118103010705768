.content{
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 40px;
    border-radius: 5px;
    box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.75);
    margin-bottom: 20px;
}

#Heading{
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: auto;
    width: auto;
}

#green-tick{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-left:10%;
    width:40px;
    background-color:#03CEA1;
    border-radius: 30px;
}

#green-tick img{
    height: 90%;
    width: 90%;
}

#Heading p{
    font-size: 30px;
    font-weight: 600;
    color: #03CEA1;
    margin-left: 10px;
}

#image-box{
    height: 50%;
    width: 50%;
    align-self: center;
}

#image1{
    height: 100%;
    width: 100%;
}

#big-text{
    height: auto;
    width: 75%;
    align-self: center;
    text-align: center;
}

#big-text p{
    font-size: 30px;
    font-weight: 600;
    color: #1B2559;
    margin: 5px;
    margin-bottom: 0px;
}

.card-content p{
    margin-left: 2%;
    width: 70%;
}

.card-content p span{
    color: #407BFF;
}

#big-text p span{
    color: #407BFF;
}

#small-text{
    height: auto;
    width: 58%;
    color: #1B2559;
    align-self: center;
    text-align: center;
}

#small-text p{
    margin-top: 0px;
    font-weight: 600;
    font-size: 16px;
}

.card1{
    display: flex;
    align-self: center;
    align-items: center;
    height: auto;
    font-weight: 600;
    border-radius: 10px;
    background-image: linear-gradient(90deg,#e5ecff,#f4f5f6);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    width: 90%;
    color: #1B2559;
}

#card1{
    margin-top: 10%;
}

#card2{
    display: flex;
    margin-top: 5%;
    margin-bottom: 30%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.card-text{
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: auto;
    width: 60%;
    margin-left: 5%;
}


#cardText1{
    font-size: 18px;
    margin-bottom: 0px;
}

#cardText2{
    font-size: 16px;
    color:#666a7e;
    margin-top: 0px;
}

#image2{
    height: 100px;
    width: 15%;
    margin-left: 5%;
    margin-top: 20px;
}

#image2 img{
    height: 80px;
    width: 90px;
}

.phone-no{
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    margin-left: 10%;   
}

.phone-no div{
    height: 20px;
    width: 20px;
    margin-right: 5px;
}


.phone-no div img{
    height: 100%;
    width: 100%;
}


@media(max-width:1200px){

    .card-content p{
        margin-left: 2%;
        width: 90%;
    }

    #image2{
        height: 100px;
        width: 200px;
        margin-left: 5%;
    }

    .phone-no{
        margin-right: 5px;
    }
}
@media (max-width: 660px){
    .phone-no{
        align-self: center;
        margin-left: 0px;
    }

    #Heading p{
        font-size: 25px;
        font-weight: 600;
        margin-left: 10px;
    }

    #card2{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #green-tick{
     height:30px;
     width:30px;
    }

    #image2{
        height: 100px;
        width: 300px;
        margin-left: 5%;
    }

    
.card-text{
    width: 50%;
    margin-right: 10px;
}

    #cardText1{
        font-size: 20px;
    }
    
    #cardText2{
        font-size: 14px;
        color:#666a7e;
        margin-top: 0px;
    }
}

@media(max-width:570px){
    .card-content{
        font-size: 14px;
    }

    .content{
        width: 100%;
    }

    #image2{
        height: 80px;
        width: 300px;
        margin-top: 0px;
    }

    #image2 img{
        height: 100%;
        width: 100%;
    }

    #green-tick{
        height: 30px;
        width: 30px;
        margin-left: 0px;
    }

    #green-tick img{
        height: 100%;
        width: 100%;
    }

    #Heading{
        align-self: center;
        justify-content: center;
        width: 100%;
    }

    #Heading p{
        font-size: 20px;
        font-weight: 600;
        color: #03CEA1;
        margin-left: 10px;
    }

    #big-text p{
        font-size: 25px;
    }

    #small-text p{
        font-size: 14px;
    }

    #image-box{
        height: 70%;
        width: 70%;
        align-self: center;
    }

}

@media(max-width:420px){
    
    #image2{
        height: 80px;
        width: 350px;
    }

    .card-text{
       margin-left: 10px;
    }

    #cardText1{
        font-size: 18px;
    }
    
    #cardText2{
        font-size: 12px;
    }

    .phone-no{
        font-size: 14px;
    }

    .phone-no div{
        margin-right: 2px;
    }
}



