@import './../Fixed/staticStyle.scss';

.contactMainHead{
    width: 100%;
    padding-inline: 10%;
    padding-block: 5%;
    background-color: #f6f7f7;    
    border-radius: 10px;

    @media(max-width:975px){
      flex-direction: column-reverse;
    }
}
.textArea{
  height: 100px;
  border-radius: 10px!important;
}
.contactUs{
  padding: 5%;
}
.contactUsHead{
  border-radius: 10px!important;
  overflow: hidden;
}
.contactImage{
  width: 100%;
  @media(max-width:975px){
    width: 70%;
  }
}