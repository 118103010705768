@import './../Fixed/staticStyle.scss';

.ourServiceCardDiv {
  width: 95%;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15%;
  padding-bottom: 15%;
  box-shadow: 5px 10px #888888;
  -webkit-box-shadow: 10px 10px 20px 7px;
  -moz-box-shadow: 10px 10px 20px 7px;
  box-shadow: 10px 10px 20px 7px;
  cursor: pointer;

  &:hover {
    scale: 1.08;
    transition: 0.3s;
  }
}

.ourServiceCardLogoDiv {
  width: 50%;
}

.ourServiceCardImg {
  width: 100%;
}

.ourServiceLine {
  height: 7px;
  width: 25%;
  margin-top: 20px;
}

.ourServiceTitleText {
  padding-top: 8%;
  color: #212121;
  font-weight: 500;
  font-size: 15px;

  @media (max-width: 767px) {
    font-size: x-small;
  }
}

.whyUsCardDiv {
  position: relative;
  overflow: hidden;
  // width: 18%;
  margin-left: 1%;
  margin-right: 1%;
  // margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 2%;
  -webkit-box-shadow: 10px 10px 20px 7px;
  -moz-box-shadow: 10px 10px 20px 7px;
  box-shadow: 10px 10px 20px 7px;
  border-bottom: 2px solid;
  cursor: pointer;
  min-height: 220px;
  margin-top: 10px;

  &:hover {
    scale: 1.08;
    transition: 0.3s;
  }

  @media (max-width: 1400px) {
    // width: 22%;
    margin-left: 1.5%;
    margin-right: 1.5%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  @media (max-width: 1000px) {
    // width: 30%;
    margin-left: 1.6%;
    margin-right: 1.6%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  @media (max-width: 975px) {
    min-height: 180px;
  }

  @media (max-width: 767px) {
    // width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
    font-size: x-small;
  }

  @media (max-width: 350px) {
    // width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;
  }
}

.whyUsCardLogoDiv {
  width: 60%;
  height: 120px;
  border-radius: 20px;
  border: solid 1px;
  background-color: #fafafa;
  z-index: 2;

  @media (max-width: 975px) {
    height: 100px;
  }
}

.whyUsCardImg {
  width: 99%;
  height: 99px;
  object-fit: contain;
  overflow: hidden;
}

.whyUsCircle {
  height: 80%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  top: -40%;
  z-index: 1;
}

.whyUsTitleText {
  padding-top: 8%;
  color: #212121;
  font-weight: 600;
  font-size: 15px;

  @media (max-width: 767px) {
    font-size: x-small;
  }
}

.howCardDivCircle {
  background-color: $lightBlueColorBackground;
  width: 230px;
  height: 230px;
  border-radius: 100%;
  box-shadow: 5px 5px 20px 10px rgb(20 83 156 / 36%);

  &:hover {
    transition: 0.3s;
    cursor: pointer;
    box-shadow: 5px 5px 20px 10px rgba(20, 83, 156, 0.591);
    // transform: rotate(360deg);
  }

  @media (max-width: 1400px) {
    width: 170px;
    height: 170px;
  }

  @media (max-width: 1200px) {
    width: 170px;
    height: 170px;
  }

  @media (max-width: 975px) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 400px) {
    margin-top: 5% !important;
    margin-left: 2% !important;
  }
}

.marginTopDynamic {
  margin-top: 20%;

  @media (max-width: 975px) {
    margin-top: 50%;
  }

  @media (max-width: 400px) {
    margin-top: 0%;
  }
}

.countDesign {
  position: absolute;
  color: white;
  font-weight: bold;
  background-color: #1c6ed0;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;

  @media (max-width: 1400px) {
    margin-left: -25px;
  }

  @media (max-width: 1200px) {
    margin-left: -25px;
  }

  @media (max-width: 975px) {
    margin-left: -25px;
  }
}

.howCardDiv {
  z-index: 2;
  color: rgba(238, 241, 244, 0.4);
  background-color: #ffffff;
  position: relative;
  width: 13%;
  height: 200px;
  margin-left: 1.83%;
  margin-right: 1.83%;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 2%;
  -webkit-box-shadow: 10px 10px 20px 7px;
  -moz-box-shadow: 10px 10px 20px 7px;
  box-shadow: 10px 10px 20px 7px;
  cursor: pointer;

  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;

  &:hover {
    // scale: 1.1;
    // transform: rotateY(360deg);
    // transition: 0.8s;
    cursor: pointer;
    animation-name: bounce;
    -moz-animation-name: bounce;
  }

  @media (max-width: 1400px) {
    width: 12%;
    margin-left: 1.5%;
    margin-right: 1.5%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  @media (max-width: 1000px) {
    width: 30%;
    margin-left: 1.6%;
    margin-right: 1.6%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  @media (max-width: 767px) {
    width: 40%;
    height: 150px;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  @media (max-width: 350px) {
    width: 60%;
    margin-top: 20%;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 10%;
    padding-bottom: 10%;
  }
}

.howCardLogoDiv {
  position: absolute;
  width: 45%;
  z-index: 3;
  margin-top: -200px;

  @media (max-width: 975px) {
    margin-top: -150px;
  }
}

.howCardImg {
  width: 99%;
  height: 99%;
  object-fit: contain;
  overflow: hidden;
}

.howCardCircleImg {
  width: 75%;
  object-fit: contain;
  overflow: hidden;
  padding: 5%;
}

.circleImageSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.howCircle {
  height: 80%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  top: -40%;
  z-index: 1;
}

.howTitleText {
  padding-top: 8%;
  color: #212121;
  font-weight: 600;
  font-size: small;

  @media (max-width: 767px) {
    font-size: x-small;
  }
}

.howHighlightedTitleText {
  padding-top: 8%;
  color: #1c6ed0;
  font-weight: 600;
  font-size: small;

  @media (max-width: 767px) {
    font-size: x-small;
  }
}

.howHighlightedCircleTitleText {
  width: 90%;
  color: #000000;
  font-size: 70%;
  font-weight: 500;

  @media (max-width: 767px) {
    font-size: x-small;
  }
}

@keyframes bounce {

  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.leftDownLineDecorationUp {
  position: absolute;
  top: 102%;
  z-index: 1;
  color: rgba(33, 33, 33, 0.7607843137);
  transform: rotate(140deg);
  font-size: 20px;
  left: -28%;

  @media (max-width: 300px) {
    display: none !important;
    opacity: 0;
  }
}


.HowCardCircleDynamicStickUp {
  display: block !important;
  position: absolute;
  margin-top: -7%;
  margin-left: 12%;
  z-index: 1;
  width: 100px;
  transform: rotate(90deg);

  @media (max-width: 1400px) {
    margin-top: -9%;
    margin-left: 9%;
  }

  @media (max-width: 1200px) {
    margin-top: -9%;
    margin-left: 9%;
  }

  @media (max-width: 975px) {
    margin-top: 15%;
    margin-left: -15%;
    width: 90px;
  }

  @media (max-width: 400px) {
    display: none !important;
  }
}

.HowCardCircleDynamicStickDown {
  display: block !important;
  position: absolute;
  margin-top: 11%;
  margin-left: 9%;
  z-index: 1;
  width: 100px;

  @media (max-width: 1400px) {
    margin-top: 9%;
    margin-left: 7%;
  }

  @media (max-width: 1200px) {
    margin-top: 9%;
    margin-left: 7%;
  }

  @media (max-width: 975px) {
    margin-top: 20%;
    margin-left: 20%;
  }

  @media (max-width: 400px) {
    display: none !important;
  }
}

.lastItemStyle {
  @media (max-width: 975px) {
    margin-right: 50%;
  }

  @media (max-width: 400px) {
    margin-right: 0%;
  }
}


.rightLineDecorationUp {
  position: absolute;
  top: 50%;
  right: -30%;
  z-index: 1;
  // background-color: red;
  color: #212121;
  font-size: 30px;
  transform: skew(10deg, -25deg);

  @media (max-width: 1600px) {
    color: #212121;
    font-size: 25px;
    right: -30%;
  }

  @media (max-width: 1400px) {
    color: #212121;
    font-size: 25px;
    right: -20%;
  }

  @media (max-width: 1200px) {
    color: #212121;
    font-size: 25px;
    right: -23%;
  }

  @media (max-width: 1000px) {
    color: #212121;
    font-size: 18px;
    right: -15%;
  }

  @media (max-width: 850px) {
    color: #212121;
    font-size: 18px;
    right: -20%;
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }

  @media (max-width: 350px) {
    display: none;
  }
}

.rightLineDecorationDown {
  position: absolute;
  top: 50%;
  right: -30%;
  z-index: 1;
  // background-color: red;
  color: #212121;
  font-size: 30px;
  transform: skew(10deg, 25deg);

  @media (max-width: 1600px) {
    color: #212121;
    font-size: 25px;
    right: -30%;
  }

  @media (max-width: 1400px) {
    color: #212121;
    font-size: 25px;
    right: -20%;
  }

  @media (max-width: 1200px) {
    color: #212121;
    font-size: 25px;
    right: -23%;
  }

  @media (max-width: 1000px) {
    color: #212121;
    font-size: 18px;
    right: -15%;
  }

  @media (max-width: 850px) {
    color: #212121;
    font-size: 18px;
    right: -20%;
  }

  @media (max-width: 767px) {
    color: #212121;
    font-size: 18px;
    right: -22%;
  }

  @media (max-width: 500px) {
    color: #212121;
    font-size: 18px;
    right: -25%;
  }

  @media (max-width: 500px) {
    color: #212121;
    font-size: 18px;
    right: -28%;
  }

  @media (max-width: 350px) {
    display: none;
  }
}

.noneDisplay {
  display: none;
}

.whyBestCardLogoDiv {
  width: 30%;
}

.whyBestCardTitleText {
  padding-top: 8%;
  color: #212121;
  font-weight: 600;
  font-size: 18px;

  @media (max-width: 1150px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: x-small;
  }
}

.industriesBigCard {
  background-color: #fff;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 975px) {
    height: 80%;
  }
}

.industriesBigCardLogo {
  // height: 60%;
  width: 100%;
  padding: 2%;

  @media (max-width: 975px) {
    height: auto;
  }
}

.industriesBigCardImg {
  width: 40%;
  // height: 100%;
  object-fit: contain;
  margin-top: 10px;

  @media (max-width: 975px) {
    height: auto;
  }
}

.industriesBigCardTitle {
  width: 100%;
  text-align: center;
  height: 10%;
  color: #1c6ed0;
  font-weight: 600;
  font-size: large;

  @media (max-width: 975px) {
    height: auto;
  }
}

.industriesBigCardDescription {
  width: 100%;
  text-align: justify;
  padding-inline: 5%;
  padding-top: 1%;
  height: 30%;
  color: #888888;
  font-size: small;

  @media (max-width: 975px) {
    font-size: x-small;
  }

  @media (max-width: 400px) {
    font-size: 8px;
  }
}

.industriesSmallCard {
  width: 100%;
  height: 18%;
  background-color: #ffffff;
  border-radius: 20px;
  border: solid 1px lightgrey;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:active {
    scale: 0.98;
  }

  @media (max-width: 975px) {
    flex-direction: column;
    align-items: center;
    height: 15%;
    margin-top: 5px;
  }
}

.industriesSmallCardLogo {
  height: 100%;
  width: 35%;
  padding: 10%;

  @media (max-width: 975px) {
    width: 100%;
    height: auto;
    padding: 0%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
  }
}

.industriesSmallCardImg {
  height: 100%;
  width: 100%;
  object-fit: contain;

  @media (max-width: 975px) {
    height: auto;
    width: 40%;
    max-height: 40px;
    object-fit: contain;
  }
}

.industriesSmallCardTitle {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  // justify-content: center;
  color: #212121;
  font-weight: 600;
  font-size: 15px;
  padding-right: 15%;

  @media (max-width: 975px) {
    width: auto;
    text-align: center;
    padding-right: 0%;
    font-size: 8px;
  }

}

.askBigCardContainer {
  transform: scale(0.9);
  height: 60vh;
  width: 100%;
  background-color: #15539c;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 2%;
  padding-block: 3%;

  @media (max-width: 975px) {
    height: auto;
    flex-direction: column;
    align-items: center;
  }

}

.askBigCardLeft {
  height: 100%;
  width: 35%;
  border-radius: 30px;
  background-color: white;
}

.askBigCardRight {
  height: 100%;
  width: 65%;
  // background-color: red;
  padding-inline: 5%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 975px) {
    width: 100%;
  }
}

.askBigCardTitle {
  color: #fafafa;
  font-weight: 600;
  font-size: 35px;
  padding-bottom: 5px;

  @media (max-width: 975px) {
    font-size: large;
    text-align: center;
    margin-top: 10px;
  }

}

.askBigCardDescription {
  // width: 50%;
  color: #dddddd;
  font-size: 15px;
  padding-bottom: 5%;

  @media (max-width: 975px) {
    width: 100%;
    font-size: small;
  }
}

.contactDetailsView {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 975px) {
    flex-direction: column;
  }
}

.contactDetailsItem {}

.contactDetailsItemHead {
  display: flex;
  flex-direction: row;
}

.contactDetailsLogo {
  width: 30px;
  background-color: $blueColorBackground;
  padding: 5px;
  border-radius: 10px;
}

.contactDetailsHeadLogo {}

.contactDetailsHeadTitle {
  padding-left: 10px;
  color: #dddddd;
  font-weight: 500;
  font-size: medium;

  @media (max-width: 975px) {
    font-size: small;
  }
}

.contactDetailsItemBody {
  color: #fafafa;
  font-weight: 600;
  font-size: large;

  @media (max-width: 975px) {
    font-size: small;
  }
}




.blogsBigCard {
  background-color: #fff;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  transition: 0.3s;

  // display: flex;
  // flex-direction: column;
  // justify-content: space-evenly;
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 10px 10px 10px #00000073;
  }

  @media (max-width: 975px) {
    height: 70%;
    padding-bottom: 10px;
  }
}

.blogsBigCard2 {
  background-color: #fff;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 10px 10px 10px #00000073;
  }

  // display: flex;
  // flex-direction: column;
  // justify-content: space-evenly;
  @media (max-width: 975px) {
    padding-bottom: 10px;
  }
}

.blogsBigCardLogo {
  // height: 60%;
  width: 100%;
  padding: 3%;

  @media (max-width: 975px) {
    height: auto;
  }
}

.blogsBigCardImg {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: fill;

  @media (max-width: 975px) {
    height: 200px;
  }
}

.blogsBigCardTitle {
  width: 100%;
  // text-align: center;
  height: 55px;
  color: #1c6ed0;
  font-weight: 600;
  font-size: medium;

  @media (max-width: 975px) {
    height: auto;
    font-size: x-small;
  }
}

.blogsDate {
  background: #e4edf9;
  width: 120px;
  padding: 2px;
  border-radius: 5px;
  text-align: center;
  font-size: small;
  color: rgba(0, 0, 0, 0.691);
  font-weight: bold;

  @media (max-width: 975px) {
    width: 50%;
    font-size: xx-small;
  }
}

.blogsBigCardDescription {
  width: 100%;
  text-align: justify;
  padding-inline: 5%;
  padding-top: 1%;
  height: 30%;
  color: #888888;
  font-size: small;
  padding-bottom: 5%;

  @media (max-width: 975px) {
    font-size: x-small;
  }
}

.blogsSmallCard {
  width: 100%;
  height: 30%;
  background-color: #ffffff;
  border-radius: 20px;
  border: solid 1px lightgrey;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:active {
    scale: 0.98;
  }

  @media (max-width: 975px) {
    flex-direction: column;
    align-items: center;
  }
}

.blogsSmallCardLogo {
  height: 100%;
  width: 35%;
  // padding: 10%;

  @media (max-width: 975px) {
    width: 100%;
    height: auto;
  }
}

.blogsSmallCardImg {
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  // object-fit: contain;

  @media (max-width: 975px) {
    padding: 0px;
    height: auto;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    // max-height: 40px;
    // object-fit: contain;
  }
}

.blogsSmallCardTitle {
  padding: 10px;
  width: 65%;
  height: 100%;
  // display: flex;
  align-items: center;
  text-align: left;
  // justify-content: center;
  color: #212121;
  font-weight: 600;
  font-size: small;
  // padding-right: 15%;

  @media (max-width: 975px) {
    width: auto;
    text-align: center;
    padding-right: 0%;
    font-size: x-small;
  }

}



